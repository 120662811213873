import React from "react";
import { Page } from "../components/Page";
import { Title } from "../components/Title";
import { Content } from "../components/Content";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const SlippingPreventionPage = () => (
  <Page page="Vältä liukastumiset">
    <Row>
      <Col>
        <Title>Vähennä liukastumisriskiä</Title>

        <Content>
          Jalankulkijoiden liukastumiset ovat erittäin yleisiä.
          Liukastumistapaturmien vakavuudet vaihtelevat hyvin lievistä
          tapauksista vakaviin, jopa kuolemaan johtaneisiin tilanteisiin.
          Useasti liukastuessa nilkka nyrjähtää, mutta myös luut joutuvat
          kovalle koetukselle. Kaatumisissa erityisesti ranteen ja lonkan seudun
          luut murtuvat helposti. Selkä saattaa venähtää jo pelkän äkillisen
          tasapainon korjausliikkeen takia. Riski vammautua kasvaa iän myötä. Jo
          40-50 vuotiaan riski on jopa kaksinkertainen 20-29 vuotiaisiin
          verrattuna.
        </Content>

        <Content>Näin vältät liukastumiset:</Content>

        <Content as="ol">
          <li>
            Kävele liukkaalla VAROVASTI, sillä kaikkia kohtia ei ole välttämättä
            hiekoitettu tai hiekoitus on voinut painua jään sisään
          </li>
          <li>
            Valitse talvikeleihin soveltuvat jalkineet ja käytä LIUKUESTEITÄ
          </li>
        </Content>

        <Content>
          <a href="https://www.kotitapaturma.fi/">
            Kotitapaturmien ehkäisykampanjan sivut
          </a>
        </Content>
      </Col>
    </Row>
  </Page>
);

export default SlippingPreventionPage;
